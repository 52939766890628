.ais-SearchBox-input{
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 8.5px 11px;
    color: #456045;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6ebf1;
    border-radius: .625rem;
    transition: all .3s;
    outline: none;    
}

.ais-SearchBox-submit{
    display: none;
}

.ais-SearchBox-reset img{
    padding-left: 5px !important;
    height: 20px;
    width: 23px;    
}

.ais-SearchBox-reset {       
    display:contents !important;
    position: fixed;
    
}

ul {
    list-style-type: none;
    margin: 0;   
    padding: 0;
}

.ais-Hits-list {
    margin: 0;   
    padding: 0;
}

.ais-Hits-item {
    margin: 0;   
    padding: 0;
}

.ant-card-bordered {
    margin: 0;   
    padding: 0;
    
}

.ant-card-meta-title {
    white-space: pre-line;
  }

.search-results{    
    display: flex;
    flex-direction: row;
    height: 80vh;
}
